import './bootstrap.js';
/*
/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';

import * as bootstrap from './template/js/bootstrap.min.js';


// ********** Main ajax method ***********
    function doAjax(url, params, callback) {

        var par = params ? params : {};

        $.post(url, par, function(data) {
            if (data.status_code === 200 ) {
                if(callback){
                    callback;
                }
            }
        }).fail(function() {
            alert('Ошибка запроса');
        });
        return false;
    }
    //********** eof Main ajax method ***********

// Dropzone.autoDiscover = false;

$(document).ready(function () {

    const uploadImageWrapper = $('#uploadImageWrapper');
    const uploadImageUrl = uploadImageWrapper.attr('data-url');

    $("#dropzone-gls").dropzone({

        url: uploadImageUrl,
        widht: '',
        height: 130,
        text: '<img src="/images/images.svg" class="w-80">'
            +'<div>Перетащите сюда фото или видео файлы</div>',
        border: '4px dashed #ccc',
        filesName: 'files',
        maxFiles:20,
        allowedFileTypes: 'jpg, jpeg, png, gif, mp4, avi, mov, mkv',
        maxFileSize: '300MB',
        success: function(res, index){
            afterImageUpload();
        }
     });


    function afterImageUpload() {
        const url = $('#media-files-wrapper').attr('data-url');

        $.post(url, function(data) {
            if (data.status_code === 200 ) {
                $('#media-files-wrapper').html(data.content);
                deleteFile();
            }

        }).fail(function() {
            alert('Ошибка запроса');
        });
    }


    function deleteFile(){

        $('.delete-file-btn').on('click', function(e){

            const url = $(this).attr('data-delete-file-url');
            const fileWrapper = $(this).parents('.file-item');
        
            doAjax(url, {}, fileWrapper.fadeOut(300, function() {
                fileWrapper.remove();
            }));
        });

    }
    deleteFile();
    


    /*----------- Edit Post form --------------*/

    function utilityBillsToggle(){
        const monthlyToggle = $('#monthly-toggle');
        const utilityBillsToggle = $('#utility-bills-toggle');

        if ($('#post_dealType').val() == 1) {
            monthlyToggle.hide();
            utilityBillsToggle.hide();
        } else {
            monthlyToggle.show();
            utilityBillsToggle.show();
        }
    }
    utilityBillsToggle();

    $('#post_dealType').on('change', function(){
        utilityBillsToggle()
    });


    $('#post_isFullAccess').on('change', function(){

        const accessTimeToggle = $('#access-time-toggle');

        if(this.checked) {
            accessTimeToggle.hide();
        } else {
            accessTimeToggle.show();
        }

    });

    $( '.select2' ).select2( {
        theme: 'bootstrap-5'
    });


    const postForm = document.getElementById("post_form");

    if (postForm) {
        postForm.addEventListener("submit", function(e){

            $.when( saveLandmarks() ).then(function() {
                return true;
            });  
            // saveLandmarks();
        });
    }
    


    function saveLandmarks(){


        if ($(".landmark-data").length > 0) {

            let array = [];
            $(".landmark-data").each(function() {
                
                let metro = $(this).attr('data-metro');    
                let distance = $(this).attr('data-distance');    
                let duration = $(this).attr('data-duration');

                let landmark = {
                    metro: metro,
                    distance: distance,
                    duration: duration,
                }

                array.push(landmark);

            });

            let data = JSON.stringify(array);
            $('#post_landmarks').val(data);

        } else {
            $('#post_landmarks').val('');
        }
        
    }


    // Disable trix editor event
    const element = document.querySelector("trix-editor");
    if (element) {
        const trix = element.editor  // is a Trix.Editor instance
        addEventListener("trix-attachment-add", function(event) {
            event.preventDefault();
        });
        
    }

    /*----------- END Edit post form --------------*/


    // flash mesages auto-close
    $(".flash-messages-wrapper .alert").fadeTo(2000, 500).slideUp(500, function(){
        $(".flash-messages-wrapper .alert").slideUp(500);
    });


    $('.publishPost').on('click', function(){

        const modalDefault = new bootstrap.Modal('#modal-default');
        const modal = modalDefault._element;

        let itemId = $(this).parents('.job-item').data('item-id');
        const modalBody = modal.querySelector('.modal-body');
        modalBody.innerHTML = '<div class="fs-4">Опубликовать объявление №'+ itemId +'?</div>';

        // Button that triggered the modal
        const button = $(this);
        const url = button.data('url');
        const actionBtn = modal.querySelector('#action-btn');
        actionBtn.innerHTML = 'Опубликовать';
        modalDefault.show();

        $('#modal-default #action-btn').on('click', function(){

            $.post(url, function(data) {
                if (data.status_code === 200 ) {
                    location.reload();
                } else {
                    alert('Произошла ошибка');
                }
            }).fail(function() {
                alert('Произошла ошибка');
            });

        });

        
    });


    $('.pausePost').on('click', function(){

        const modalDefault = new bootstrap.Modal('#modal-default');
        const modal = modalDefault._element;

        let itemId = $(this).parents('.job-item').data('item-id');
        const modalBody = modal.querySelector('.modal-body');
        modalBody.innerHTML = '<div class="fs-4">Приостановить объявление №'+ itemId +'?</div>';

        // Button that triggered the modal
        const button = $(this);
        const url = button.data('url');
        const actionBtn = modal.querySelector('#action-btn');
        actionBtn.innerHTML = 'Приостановить';
        modalDefault.show();

        $('#modal-default #action-btn').on('click', function(){

            $.post(url, function(data) {
                if (data.status_code === 200 ) {
                    location.reload();
                } else {
                    alert('Произошла ошибка');
                }
            }).fail(function() {
                alert('Произошла ошибка');
            });

        });

        
    });


    $('.closePost').on('click', function(){

        const modalDefault = new bootstrap.Modal('#modal-default');
        const modal = modalDefault._element;

        let itemId = $(this).parents('.job-item').data('item-id');
        const modalBody = modal.querySelector('.modal-body');
        modalBody.innerHTML = '<div class="fs-4">Закрыть объявление №'+ itemId +'?</div>';

        // Button that triggered the modal
        const button = $(this);
        const url = button.data('url');
        const actionBtn = modal.querySelector('#action-btn');
        actionBtn.innerHTML = 'Закрыть';
        modalDefault.show();

        $('#modal-default #action-btn').on('click', function(){

            $.post(url, function(data) {
                if (data.status_code === 200 ) {
                    location.reload();
                } else {
                    alert('Произошла ошибка');
                }
            }).fail(function() {
                alert('Произошла ошибка');
            });

        });

        
    });



    // const feedbackModal = new bootstrap.Modal('#feedbackModal');

    $('#mainFeedbackInit').on('click', function(){

        const modalBase = new bootstrap.Modal('#modalNoFooter');
        const modal = modalBase._element;

        // Button that triggered the modal
        const button = $(this);
        const url = button.data('url');

        $.post(url, {}, function(data) {
            if (data.status_code === 200 ) {
                const modalTitle = modal.querySelector('.modal-title');
                modalTitle.innerHTML = 'Обратная связь';

                const modalBody = modal.querySelector('.modal-body');
                modalBody.innerHTML = data.form;

                modalBase.show();
            }
        }).fail(function() {
            alert('Произошла ошибка');
        });

    });


    $('#adFeedbackInit').on('click', function(){

        const modalBase = new bootstrap.Modal('#modalNoFooter');
        const modal = modalBase._element;

            // Button that triggered the modal
            const button = $(this);
            const url = button.data('url');
            const adTitle = button.data('ad-title');
            const itemId = button.data('item-id');

            $.post(url, {}, function(data) {
                if (data.status_code === 200 ) {

                    const modalBody = modal.querySelector('.modal-body');
                    modalBody.innerHTML = data.form;

                    const modalTitle = modal.querySelector('.modal-title');
                    modalTitle.innerHTML = 'Обратная связь';
                    
                    const adTitleField = modal.querySelector('#form_ad_title');
                    adTitleField.value = adTitle;

                    const itemIdField = modal.querySelector('#form_item_id');
                    itemIdField.value = itemId;

                    modalBase.show();
                }
            }).fail(function() {
                alert('Произошла ошибка');
            });

    });


    $('#showPhoneInit').on('click', function(){

        const button = $(this);
        const url = button.data('url');
        const itemId = button.data('item-id');

        $.post(url, {}, function(data) {
            if (data.status_code === 200 ) {
                
                let constStr = '<h4 class="mb-0">'+ data.phone +'</h4>Номер только для звонков';
                const showPhoneWrapper = document.getElementById('showPhoneWrapper');
                showPhoneWrapper.innerHTML = constStr;

            }
        }).fail(function() {
            alert('Произошла ошибка');
        });

    });


    /*----------- Simple search form --------------*/

    const urlAuto = $('#simpleSearchWrapper').data('url-autocomplete');

    $( "#simple_search_location" ).autocomplete({
        source: function( request, response ) {
           // Fetch data
            $.ajax({
                url: urlAuto,
                type: 'post',
                dataType: "json",
                data: {
                    search_string: $("#simple_search_location").val()
                },
                success: function( data ) {
                    response( data );
                }
            });
        }
    });

    $('#simple_search_location').on('focus', function (e) {

        const simpleSearchLocation = document.getElementById('simple_search_location');
        const tooltip = new bootstrap.Tooltip(simpleSearchLocation, {
            title:'Начните печатать название города',
            trigger:'manual'
        });
        tooltip.show();
        simpleSearchLocation.addEventListener('shown.bs.tooltip', () => {
            setTimeout(function() {
               tooltip.hide();
            }, 3000);
        });
    });


    $('#simple_search_find').on('click', function (e) {

        let selects = [$('#simple_search_deal_type'), $('#simple_search_location')];
        let inputs = [$('#simple_search_price_max'), $('#simple_search_keywords')];

        let isValid = false;

        selects.forEach(element => {
            if (element.val().length > 0) {
               isValid = true;
            }
        });

        inputs.forEach(element => {
            if (element.val().length > 0) {
               isValid = true;
            }
        });

        if (isValid) {
            $('#simpleSearchWrapper form').submit();
        } else {
            const simpleSearchFind = document.getElementById('simple_search_find');
            const tooltip = new bootstrap.Tooltip(simpleSearchFind, {trigger:'manual'});
            tooltip.show();
            simpleSearchFind.addEventListener('shown.bs.tooltip', () => {
                setTimeout(function() {   //calls click event after a certain time
                   tooltip.hide();
                }, 2000);
            });
        }
        
    });


    /*-----------END Simple search form --------------*/
    
});


