
const N = 2;


ymaps.modules.define('MultiRouteCustomView', [
    'util.defineClass'
], function (provide, defineClass) {
    // Класс простого текстового отображения модели мультимаршрута.
    function CustomView (multiRouteModel, metroObj) {
        this.multiRouteModel = multiRouteModel;
        this.metroObj = metroObj;
        // Объявляем начальное состояние.
        this.state = "init";
        this.stateChangeEvent = null;
        // Элемент, в который будет выводиться текст.
        this.outputElement = $('<div></div>').appendTo('#viewContainer');

        this.rebuildOutput();

        // Подписываемся на события модели, чтобы
        // обновлять текстовое описание мультимаршрута.
        multiRouteModel.events
            .add(["requestsuccess", "requestfail"], this.onModelStateChange, this);
    }

    // Таблица соответствия идентификатора состояния имени его обработчика.
    CustomView.stateProcessors = {
        init: "processInit",
        // requestsend: "processRequestSend",
        requestsuccess: "processSuccessRequest",
        // requestfail: "processFailRequest"
    };

    defineClass(CustomView, {
        // Обработчик событий модели.
        onModelStateChange: function (e) {
            // Запоминаем состояние модели и перестраиваем текстовое описание.
            this.state = e.get("type");
            this.stateChangeEvent = e;

            this.rebuildOutput();

        },

        rebuildOutput: function () {
            // Берем из таблицы обработчик для текущего состояния и исполняем его.
            const processorName = CustomView.stateProcessors[this.state];
            // this.outputElement.html(
            return this[processorName](this.multiRouteModel, this.stateChangeEvent)
              // );
           
        },

        processInit: function () {
            return;
        },

        processSuccessRequest: function (model, e) {
            let routes = model.getRoutes(),
                result = [this.metroObj.properties.get('name')]
                data = {}
                wrapper = $('#landmarks');

            if (routes.length) {
                for (var i = 0, l = 1; i < l; i++) {

                    let route = routes[i];

                    data = {
                        metro: this.metroObj.properties.get('name'),
                        distance: route.properties.get("distance").text,
                        duration: route.properties.get("duration").text,
                    }

                    let str = 
                        '<span class="landmark-data color-success mr-10" data-metro="'+ data.metro +'"  data-distance="'+ data.distance +'" data-duration="'+ data.duration +'">'
                             +'<img src="/images/walk-icon.svg"> '
                            + data.duration +' '
                            +' '+ data.metro
                            +' ('+ data.distance +')'
                        +'</span>';
                    wrapper.append(str);
                }
            }

            return data;
        },

    });

    provide(CustomView);
});


function init (MultiRouteCustomView) {

    let address = null;

    let city = $('#post_location option:selected').text();
    let cityId = $('#post_location option:selected').val();
    let addr = $('#post_address').val();
    let metroCity = ['1', 189, 2019, 2128, 2643, 2807, 3154];


    if (addr.length > 0) {
        address = city +' '+ addr;
    } else {
        return;
    }

    if (metroCity.includes(cityId) == false) {
        $('#landmarks').html('');
        return;
    }

    $('#landmarks').html('');

    ymaps.geocode(address, {
        results: N // Если нужен только один результат, экономим трафик пользователей
    }).then(function (res) {
        // Выбираем первый результат геокодирования.
        const firstGeoObject = res.geoObjects.get(0),
        // Координаты геообъекта.
        coords = firstGeoObject.geometry.getCoordinates(),
        // Область видимости геообъекта.
        bounds = firstGeoObject.properties.get('boundedBy');

        ymaps.geocode(coords.join(','), {kind: 'metro'}).then(function(res) {

            for (var i = 0; i < N; i++) {

                metroObj = res.geoObjects.get(i);
                coordsMetro = metroObj.geometry.getCoordinates();
                distance = Math.floor(ymaps.coordSystem.geo.getDistance(coords, coordsMetro));
          
                let multiRouteModel = new ymaps.multiRouter.MultiRouteModel([
                    address,
                    coordsMetro
                ],
                {
                    routingMode: 'pedestrian', // Пешком
                });
                    
                // Создаем экземпляр текстового отображения модели мультимаршрута.
                new MultiRouteCustomView(multiRouteModel, metroObj);

            }

        });
    

    });
} // end init


function getLandmarks() {

    ymaps.ready(function () {
        ymaps.modules.require(['MultiRouteCustomView'], init)
    });
}


$(document).ready(function () {

    const wrapper = document.getElementById('postEditFormWrapper');


    if (wrapper) {

        $('#post_location').on('change', function(){
            getLandmarks();
        });
        
        $('#post_address').on('change', function(){
            getLandmarks();
        });
    }

});




