

import './font-awesome-4.7.0/css/font-awesome.min.css';
import './template/lib/animate/animate.min.css';
import './template/lib/owlcarousel/assets/owl.carousel.min.css';
import './template/css/bootstrap.min.css';
import './select2/select2.min.css';
import './select2/select2-bootstrap-5-theme.min.css';
import './template/css/style.css';

const $ = require('jquery');

// GlsCoreBundle config trix-editor +
import '../vendor/gls/core-bundle/Resources/config/frontend.js';

import './template/js/jquery-3.4.1.min.js';
import './template/js/bootstrap.min.js';
import './template/lib/wow/wow.min.js';
import './template/lib/easing/easing.min.js';
import './template/lib/waypoints/waypoints.min.js';
import './template/lib/owlcarousel/owl.carousel.min.js';

import './jquery-ui-1.14/jquery-ui.min.js';
import './jquery-ui-1.14/jquery-ui.min.css';

import './select2/select2.min.js';
import './select2/i18n/ru.js';

import './project/js/ymap-api.js';
import './project/js/ymap-landmarks.js';

// Fotorama
import './fotorama/fotorama.css';
import './fotorama/fotorama.js';

// Lightbox
import './lightbox/css/lightbox.css';
import './lightbox/js/lightbox.js';

// Dropzone
// import '../assets/dropzone/dropzone.css';
// import '../assets/dropzone/dropzone.min.js';

// Stepper
import './stepper/bs-stepper.min.css';

// Plyr
import './plyr/plyr.css';
import './plyr/plyr.js';

// Jquery Validation
// import '../assets/jquery-validation/jquery.validate.min.js';


// Project scripts
import './styles/helper.css';
import './styles/app.css';
// import './project/js/upload-chunked-file.js';
import './project/js/dropzone-gls.js';
import './template/js/main.js';
import './app.js';
