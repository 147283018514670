

ymaps.ready(init);



function init() {

    let map,
        placemark,
        i = 0;

    window.addEventListener('scroll', initMap);

    function initMap(){

        const element = document.getElementById("map");

        if (element) {
            let position = element.getBoundingClientRect();

            // checking for partial visibility
            if(position.top < window.innerHeight && position.bottom >= 0 && i == 0) {
                // console.log(i);
                i = 1;
                geocode();
            }
        }
        
    }

    function geocode() {

        // Забираем адрес.
        var request = $('#map').attr('data-location');

        // Геокодируем данные.
        ymaps.geocode(request).then(function (res) {
            var obj = res.geoObjects.get(0),
                error, hint;

            if (obj) {

                // Об оценке точности ответа геокодера можно прочитать тут: https://tech.yandex.ru/maps/doc/geocoder/desc/reference/precision-docpage/
                switch (obj.properties.get('metaDataProperty.GeocoderMetaData.precision')) {
                    case 'exact':
                        break;
                    case 'number':
                    case 'near':
                    case 'range':
                        error = 'Неточный адрес, требуется уточнение';
                        hint = 'Уточните номер дома';
                        break;
                    case 'street':
                        error = 'Неполный адрес, требуется уточнение';
                        hint = 'Уточните номер дома';
                        break;
                    case 'other':
                    default:
                        error = 'Неточный адрес, требуется уточнение';
                        hint = 'Уточните адрес';
                }
            } else {
                error = 'Адрес не найден';
                hint = 'Уточните адрес';
            }

            // Если геокодер возвращает пустой массив или неточный результат, то показываем ошибку.
            if (error) {
                showError(error);
                showMessage(hint);
            } else {
                showResult(obj);
            }
        }, function (e) {
            console.log(e)
        })

    }

    function showResult(obj) {

        // Удаляем сообщение об ошибке, если найденный адрес совпадает с поисковым запросом.
        $('#notice').css('display', 'none');

        var mapContainer = $('#map'),
            bounds = obj.properties.get('boundedBy'),
        // Рассчитываем видимую область для текущего положения пользователя.
            mapState = ymaps.util.bounds.getCenterAndZoom(
                bounds,
                [mapContainer.width(), mapContainer.height()]
            ),
        // Сохраняем полный адрес для сообщения под картой.
            address = [obj.getCountry(), obj.getAddressLine()].join(', '),
        
        // Сохраняем укороченный адрес для подписи метки.
            shortAddress = [obj.getThoroughfare(), obj.getPremiseNumber(), obj.getPremise()].join(' ');

        // Убираем контролы с карты.
        // mapState.controls = [];

        // Создаём карту.
        createMap(mapState, shortAddress);

        // Выводим сообщение под картой.
        showMessage(address);
    }

    function showError(message) {
        $('#notice').text(message);
        $('#notice').css('display', 'block');
        // Удаляем карту.
        if (map) {
            map.destroy();
            map = null;
        }
    }

    function createMap(state, caption) {

        if (!map) {
            
            map = new ymaps.Map('map', state);

            placemark = new ymaps.Placemark(
                map.getCenter(), {
                    iconCaption: caption,
                    balloonContent: caption
                }, {
                    preset: 'islands#redDotIconWithCaption'
                });
            map.geoObjects.add(placemark);
            // Если карта есть, то выставляем новый центр карты и меняем данные и позицию метки в соответствии с найденным адресом.
            
            map.behaviors.disable('scrollZoom');
        }
    }

    function showMessage(message) {
        $('#message').text(message);
    }


    function elementInViewport(el) {

        let rect = el.getBoundingClientRect(),
        percentVisible = 5,
        windowHeight = (window.innerHeight || document.documentElement.clientHeight);

          return !(
            Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100)) < percentVisible ||
            Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
          )
    }
}
